import React, { useRef, useEffect } from "react"
import { withPrefix } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import lottie from "lottie-web"

import useNearScreen from "../../../../hooks/useNearScreen"
import BgBlock from "../../../elements/bgBlock/BgBlock"
import SvgArrowDown from "../../../elements/svgs/SvgArrowDown"
import { StyledHomeIntro, StyledHomeLogo, StyledHomeIntroTag } from "./HomeIntro.style"

const HomeIntro = ({ className = "" }) => {
  const classNameID = `o-home-intro`
  const { element, show } = useNearScreen({ threshold: 0.9, onlyShow: true })
  const path = `${withPrefix("/")}assets/animations/`
  const titleLottie = useRef(null)
  const logoImage = useRef(null)

  // Lotties
  const addLotties = () => {
    titleLottie.current = lottie.loadAnimation({
      container: element.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: `${path}intro_title/data.json`,
    })
  }

  useEffect(() => {
    addLotties()

    return () => {
      if (titleLottie.current) {
        titleLottie.current.stop()
        titleLottie.current.destroy()
        titleLottie.current = null
      }
    }
  }, [])

  useEffect(() => {
    let timeout
    let interval
    if (show) {
      if (titleLottie.current.currentFrame !== 0) {
        logoImage.current.classList.add("is-visible")
      }
      interval = setInterval(() => {
        const ratio = titleLottie.current.currentFrame / titleLottie.current.totalFrames
        if (ratio > 0.02) {
          clearInterval(interval)
          logoImage.current.classList.add("is-visible")
        }
      }, 10)
      timeout = setTimeout(() => {
        titleLottie.current.play()
      }, 600)
    }

    return () => {
      clearTimeout(timeout)
      clearTimeout(interval)
    }
  }, [show])

  return (
    <StyledHomeIntro className={`${classNameID} ${className}`}>
      <BgBlock type="intro" theme="dark" depth={0} start="top bottom" end="center top">
        <div className={`${classNameID}__stage`}>
          <StyledHomeLogo ref={logoImage}>
            <StaticImage className={`${classNameID}__plate`} src="../../../../images/jeep-aniversario-logo.png" alt="Jeep 80 aniversario" placeholder="none" />
          </StyledHomeLogo>
          <StyledHomeIntroTag>
            <div className={`${classNameID}__title`} ref={element} />
          </StyledHomeIntroTag>
          <div className={`${classNameID}__description`}>
            <p>Cumplimos 80 años recorriendo caminos llenos de aventura, superando obstáculos y llegando cada vez más lejos. Esto no habría sido posible sin ti, que has convertido a esta comunidad en una filosofía de vida.</p>
            <p>Llegó el momento para compartir tus historias y que sigan siendo parte de nuestro legado.</p>
            <p>
              Esto es <strong>JEEPSTORIES.</strong>
            </p>
          </div>
          <div className={`${classNameID}__arrow-svg`}>
            <SvgArrowDown />
          </div>
        </div>
      </BgBlock>
    </StyledHomeIntro>
  )
}

export default HomeIntro
