import React, { useState, useRef, useEffect } from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import gsap from "gsap"

import useBreakpointWidth from "../../../hooks/useBreakpointWidth"
import useWindowSize from "../../../hooks/useWindowSize"
import { isIOS } from "../../../helpers/platform"
import StoryCard from "../../elements/storyCard/StoryCard"
import BgBlock from "../../elements/bgBlock/BgBlock"
import { StyledStoriesPage } from "./StoriesPages.style"

export const StoriesPageType = { top: "top", middle: "middle", bottom: "bottom" }

const StoriesPage = ({ stories, pagenumber, type }) => {
  const classNameID = `o-home-page`
  let index = -1
  const base = useRef(null)
  const tl = useRef(null)
  const size = useBreakpointWidth()
  const windowSize = useWindowSize()
  const [depthArraySize, setDepthArraySize] = useState([])
  const [cardOverIndex, setCardOverIndex] = useState(-1)
  const depthTop = {
    xs: [0.3, 0.25, 0.2, 0.15, 0.1],
    sm: [0.4, 0.9, 0.7, 0.4, 0.7],
    md: [1.5, 0.6, 0.8, 0.8, 0.4],
    lg: [1.5, 0.6, 0.8, 0.8, 0.4],
    xl: [1.7, 1, 1.3, 0.7, 0.4],
  }
  const depthBottom = {
    xs: [0.3, 0.25, 0.2, 0.15],
    sm: [1.2, 0.9, 0.5, 0.25],
    md: [1.5, 1.2, 0.9, 0.5],
    lg: [0.4, 0.21, 0.25, 0.1],
    xl: [1.5, 1.2, 0.9, 0.5],
  }
  const onCardMouseOver = i => {
    setCardOverIndex(i)
  }
  const onCardMouseOut = () => {
    setCardOverIndex(-1)
  }
  const getStory = (story, location) => {
    index += 1
    if (index > 8) {
      index = 0
    }
    return <StoryCard story={story} index={index} key={`story_card_${pagenumber}_${index}`} classTag={`tag-historias-${story.tipo}`} depth={depthArraySize[index]} className={`story_card_${location}_${index} storiesparallax`} isOvered={cardOverIndex === index} isSomeCardIsOvered={cardOverIndex !== -1} mouseEnterHandler={onCardMouseOver} mouseLeaveHandler={onCardMouseOut} />
  }

  useEffect(() => {
    // console.log(" > StoriesPage", pagenumber, "type", type, "stories", stories.length)
    if (!isIOS()) {
      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: base.current,
          start: "top bottom",
          end: "bottom top",
          scrub: 0.5,
          invalidateOnRefresh: true,
          toggleClass: "is-active",
          // markers: true,
        },
      })
    }

    return () => {
      if (tl.current) {
        tl.current.clear()
        tl.current.kill()
        tl.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (!isIOS()) {
      if (windowSize.width !== 0 && tl.current) {
        tl.current.clear()

        gsap.utils.toArray(`.o-home-page__number${pagenumber}.o-home-page__${type} .storiesparallax`).forEach(layer => {
          const { depth } = layer.dataset
          const movement = -(layer.offsetHeight * depth)
          tl.current.fromTo(layer, { y: 0 }, { y: movement, ease: "none" }, 0)
        })
      }

      ScrollTrigger.refresh()
    }
  }, [depthArraySize])

  useEffect(() => {
    if (windowSize.width === 0) return

    switch (size.min) {
      case 767:
        setDepthArraySize(type === StoriesPageType.top || type === StoriesPageType.bottom ? depthTop.xs : depthBottom.xs)
        break
      case 768:
        setDepthArraySize(type === StoriesPageType.top || type === StoriesPageType.bottom ? depthTop.sm : depthBottom.sm)
        break
      case 1024:
      case 1360:
        setDepthArraySize(type === StoriesPageType.top || type === StoriesPageType.bottom ? depthTop.md : depthBottom.md)
        break
      case 1920:
        setDepthArraySize(type === StoriesPageType.top || type === StoriesPageType.bottom ? depthTop.xl : depthBottom.xl)
        break
      default:
    }

    if (!isIOS()) {
      ScrollTrigger.refresh()
    }
  }, [windowSize.width])

  return (
    <>
      <StyledStoriesPage className={`${classNameID} ${classNameID}__${type} ${classNameID}__number${pagenumber}`} key={`key__${pagenumber}__${type}`}>
        <div className={`${classNameID}__trigger`} ref={base} />
        <BgBlock theme="light" heightPercentToReduce={40}>
          <Container>
            {type === StoriesPageType.top && (
              <Row>
                <Col xs={12} sm={12} md={8} lg={8}>
                  <div className={`${classNameID}__left`}>{stories.slice(0, 3).map(storyData => getStory(storyData, "top"))}</div>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <div className={`${classNameID}__right`}>{stories.slice(3, 5).map(storyData => getStory(storyData, "top"))}</div>
                </Col>
              </Row>
            )}
            {type === StoriesPageType.middle && (
              <Row>
                <Col xs={12} sm={4} md={6} lg={6}>
                  <div className={`${classNameID}__left`}>{stories.slice(5, 7).map(storyData => getStory(storyData, "bottom"))}</div>
                </Col>
                <Col xs={12} sm={4} md={6} lg={6}>
                  <div className={`${classNameID}__right`}>{stories.slice(7, 9).map(storyData => getStory(storyData, "bottom"))}</div>
                </Col>
              </Row>
            )}
            {type === StoriesPageType.bottom && (
              <Row>
                <Col xs={12} sm={12} md={8} lg={8}>
                  <div className={`${classNameID}__left`}>{stories.slice(9, 12).map(storyData => getStory(storyData, "top"))}</div>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <div className={`${classNameID}__right`}>{stories.slice(12, 14).map(storyData => getStory(storyData, "top"))}</div>
                </Col>
              </Row>
            )}
          </Container>
        </BgBlock>
      </StyledStoriesPage>
    </>
  )
}

const propsAreEqual = (prevData, nextData) => {
  // console.log(`   > StoriesPage propsAreEqual`, prevData, nextData)

  return prevData.pagenumber === nextData.pagenumber && prevData.type === nextData.type
}
export default React.memo(StoriesPage, propsAreEqual)
