import styled from "styled-components"
import { device } from "../../../../theme/mediaQueries"

const StyledHomeAnniversary = styled.div`
  display: block;
  padding: 20vw 0;
  position: relative;
  z-index: 1;

  .o-home-storytelling__logocontainer {
    width: 100%;
  }
  .o-home-storytelling__logo {
    display: block;
    margin: 0 auto;
    max-width: 600px;
    position: relative;
    width: 90%;
    width: calc(100% - 30px);
    text-align: center;
  }
  .o-home-storytelling__badge {
    width: 200px;

    @media ${device.md} {
      width: 260px;
    }
    @media ${device.lg} {
      width: 385px;
      height: 385px;
    }
  }
  .o-home-storytelling__logodescription {
    color: ${props => props.theme.vars.mirage};
    font-family: ${props => props.theme.vars.avenirNext};
    font-size: 16px;
    font-weight: ${props => props.theme.vars.fontExtraLight};
    line-height: 25px;
    margin-top: 30px;
    padding: 0 30px;
    text-align: center;

    p {
      font-family: ${props => props.theme.vars.avenirNext};
      font-size: 16px;
      font-weight: ${props => props.theme.vars.fontSemiBold};
    }
  }
  .o-home-storytelling__toscroll {
    position: relative;
    display: block;
    margin-top: 64px;
    margin-bottom: 56px;
    text-align: center;
  }

  @media (min-width: 650px) {
    padding: 14vw 0;
  }
  @media ${device.sm} {
    padding: 10vw 0;

    .o-home-storytelling__logo {
      max-width: 85%;
      width: 100%;
    }
  }
  @media ${device.md} {
    padding: 6vw 0 12vw;

    .o-home-storytelling__logo {
      max-width: 700px;
    }
    .o-home-storytelling__logodescription {
      width: 750px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 46px;
    }
  }
  @media ${device.lg} {
    padding: 4vw 0 8vw;

    .o-home-storytelling__logo {
      max-width: 825px;
    }
    .o-home-storytelling__logodescription {
      width: 910px;
      margin-top: 46px;

      p {
        font-size: 20px;
        line-height: 35px;
      }
    }
  }
  @media (min-width: 1600px) {
    padding: 2vw 0 10vw;

    .o-home-storytelling__logo {
      max-width: 970px;
    }
    .o-home-storytelling__logodescription {
      width: 1165px;
      margin-top: 71px;

      p {
        font-size: 22px;
        line-height: 38px;
      }
    }
  }
`

export default StyledHomeAnniversary
