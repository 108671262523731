import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const StyledStoriesPages = styled.div`
  pointer-events: none;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  z-index: 4;
`
export const StyledStoriesPage = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  z-index: 2;

  .c-place--date {
    font-family: ${props => props.theme.vars.tradeGot};
    font-size: 10px;
    font-weight: ${props => props.theme.vars.fontBold};
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: 1px;
    color: ${props => props.theme.vars.charade};
    width: calc(100% - 60px);
    text-align: center;
  }
  .c-place--divisor {
    display: inline-block;
    margin: 0px 10px;
    width: 1px;
    height: 8px;
    background-color: ${props => props.theme.vars.charade};
  }
  .o-home-page__trigger {
    /* border: 1px solid red; */
    height: 100%;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .o-home-page__left,
  .o-home-page__right {
    @media ${device.sm} {
      padding-top: 40px;
      margin-bottom: -40px;
    }
    @media ${device.md} {
      padding-top: 30px;
      margin-bottom: -30px;
    }
    @media ${device.lg} {
      padding-top: 60px;
      margin-bottom: -60px;
    }
    @media ${device.xl} {
      padding-top: 20px;
      margin-bottom: -250px;
    }
  }
  .o-home-page__left {
    @media ${device.sm} {
      width: 100%;
      display: block;

      .o-story-card {
        width: calc(50% - 12px);
        display: inline-block;
        justify-content: space-between;
      }
      .o-story-card__set1 {
        float: right;
      }
      .o-story-card__set2 {
        width: 100%;
        margin-top: 50px;
      }
    }
    @media ${device.md} {
      .o-story-card__set2 {
        margin-top: 50px;
      }
    }
    @media ${device.xl} {
      .o-story-card__set2 {
        margin-top: 100px;
      }
    }
  }
  .o-home-page__right {
    @media ${device.sm} {
      .o-story-card {
        width: calc(50% - 12px);
        display: inline-block;
        justify-content: space-between;
      }
      .o-story-card__set4 {
        float: right;
      }
    }
  }
  &.o-home-page__middle {
    .o-home-page__left,
    .o-home-page__right {
      @media ${device.sm} {
        margin-bottom: -10px;
      }
      @media ${device.md} {
        margin-bottom: -120px;
      }
      @media ${device.lg} {
        margin-bottom: -10px;
      }
      @media ${device.xl} {
        margin-bottom: -70px;
      }
    }
  }
  &.o-home-page__top,
  &.o-home-page__bottom {
    @media ${device.md} {
      .o-home-page__left {
        display: inline-block;
        margin-top: 130px;
      }
      .o-home-page__right {
        vertical-align: top;
        float: right;
        .o-story-card {
          width: 100%;
        }
      }
    }
    @media ${device.xl} {
      .o-story-card {
        height: 597px;
      }
    }
  }
  &.o-home-page__middle {
    @media ${device.sm} {
      .o-home-page__left,
      .o-home-page__right {
        width: 100%;
        display: inline-block;
        .o-story-card {
          width: 100%;
        }
      }
      .o-home-page__left {
        margin-top: 80px;
      }
      .o-home-page__right {
        float: right;
      }
    }
    @media ${device.md} {
      .o-story-card {
        height: 447px;
      }
      .o-home-page__left {
        margin-top: 180px;
        .o-story-card:last-child {
          width: 80%;
        }
        .o-story-card__set6 {
          width: 64%;
          height: 385px;
          float: right;
        }
      }
      .o-home-page__right {
        .o-story-card:first-child {
          width: 80%;
        }
      }
    }
    @media ${device.lg} {
      .o-story-card {
        height: 540px;
      }
      .o-home-page__left {
        margin-top: 150px;

        .o-story-card__set6 {
          height: 470px;
        }
      }
    }
    @media ${device.xl} {
      .o-story-card {
        height: 720px;
      }
      .o-home-page__left {
        .o-story-card__set6 {
          height: 600px;
        }
      }
    }
  }
`
