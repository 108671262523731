import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react"
import ReactPlayer from "react-player/lazy"

import useNearScreen from "../../../hooks/useNearScreen"

import SvgRol from "../svgRoll/SVGRoll"
import StyledPlayer, { StyledButtonPlayer } from "./videoPlayer.styles"

const videoSocial = forwardRef(({ url = "https://www.youtube.com/watch?v=VplueiXaWCY", isSocialVideo = true, poster = "", customPoster = null, className = "" }, ref) => {
  const [playing, setPlaying] = useState(false)
  const [showCustomPoster, setShowCustomPoster] = useState(true)
  const [mouseOver, setMouseOver] = useState(false)

  const { element, show } = useNearScreen({
    threshold: 0.25,
    onlyShow: false,
  })
  const player = useRef()

  useEffect(() => {
    if (show) return
    setPlaying(false)
  }, [show])

  const handlePlay = () => () => {
    setPlaying(true)
    setMouseOver(false)
  }

  const handlePause = () => {
    setPlaying(false)
  }

  const handleVideoEnd = () => () => {
    handlePause()
  }

  useImperativeHandle(ref, () => ({
    pause: () => handlePause(),
  }))

  return (
    <StyledPlayer ref={element} style={{ width: "100%", height: "100%" }} className={`c-video-player ${className}`} animatePoster={show}>
      <ReactPlayer
        ref={player}
        className="react-player"
        url={url}
        width="100%"
        height="100%"
        playing={playing}
        onPlay={() => setPlaying(true)}
        onStart={() => setShowCustomPoster(false)}
        onEnded={handleVideoEnd()}
        config={{
          file: { attributes: { poster } },
        }}
      />

      {customPoster && showCustomPoster && !isSocialVideo && <img className="c-custom-poster" src={customPoster} alt="" />}

      {!isSocialVideo && !playing && (
        <div
          onClick={handlePlay}
          onKeyPress={null}
          role="button"
          tabIndex="0"
          onMouseEnter={() => {
            setMouseOver(true)
          }}
          onMouseLeave={() => {
            setMouseOver(false)
          }}
          className="c-video-player--play"
        >
          <SvgRol mouseOver={mouseOver} />
        </div>
      )}

      {!isSocialVideo && playing && (
        <StyledButtonPlayer className="c-video-player--pause-container" onClick={() => handlePause()}>
          <div className="c-video-player--pause">
            <span />
            <span />
          </div>
        </StyledButtonPlayer>
      )}
    </StyledPlayer>
  )
})

export default videoSocial
