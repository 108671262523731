import React, { useState, useEffect } from "react"

import PageContainer from "./pageContainer"

const StoriesPages = ({ items, totalPages }) => {
  const [allItems, setAllItems] = useState([])

  useEffect(() => {
    if (items.length < 14) {
      const size = 14
      const totalRest = size - items.length
      const referArray = allItems[0] ? allItems[0] : items
      const restOfItems = []
      let count = 0
      // const restOfItems = allItems[0] ? allItems[0].slice(0, size - items.length) : items.slice(0, size - items.length)
      for (let i = 0; i < totalRest; i += 1) {
        restOfItems.push(referArray[count])
        count += 1
        if (count === referArray.length) {
          count = 0
        }
      }
      const completeItems = [...items, ...restOfItems]
      setAllItems([...allItems, ...[completeItems]])
    } else {
      setAllItems([...allItems, ...[items]])
    }
  }, [items])

  if (allItems.length === 0) return null

  return (
    <>
      {allItems.map((stories, index) => {
        return <PageContainer key={`page_container_${index}`} stories={stories} index={index} totalPages={totalPages} />
      })}
    </>
  )
}

export default StoriesPages
