import styled from "styled-components"
import { device } from "../../../../theme/mediaQueries"
import easeInOutExpo from "../../../../theme/easings"

export const StyledHomeFooterTop = styled.div`
  pointer-events: none;
  position: relative;
  text-align: center;
  width: 100vw;

  &.is-last {
    &.o-home--footer__container {
      margin-top: 8vw;
      z-index: 4;
    }
    .o-home--footer__top-layer-container {
      height: 100%;
    }
    .o-home--footer__top-layer {
      visibility: visible !important;
    }
    .o-home--footer__top {
      min-height: 82vw;
    }
  }
  &.no-fixed {
    .o-home--footer__top-layer {
      transform: translate(0px, -50%) !important;
      visibility: visible !important;
    }
  }
  &.o-home--footer__top-layer-container,
  .o-home--footer__top-layer,
  .o-home--footer__bottom-layer {
    min-height: 550px;
  }
  .o-home--footer__bg-container {
    max-height: 550px;
  }
  .o-home--footer__bottom-layer {
    position: relative;
  }
  .o-home--footer__share,
  .o-home--footer__dark-bg-container {
    background-color: ${props => props.theme.vars.steelGray};
  }
  .o-home--footer__dark-bg-container {
    padding-top: 5vw;
  }

  @media (min-width: 400px) {
    &.is-last {
      &.o-home--footer__container {
        margin-top: 5vw;
      }
      .o-home--footer__top {
        min-height: 65vw;
      }
    }
    &.o-home--footer__top-layer-container,
    .o-home--footer__top-layer,
    .o-home--footer__bottom-layer {
      min-height: 700px;
    }
    .o-home--footer__bg-container {
      max-height: 700px;
    }
  }
  @media (min-width: 550px) {
    &.is-last {
      &.o-home--footer__container {
        margin-top: 10vw;
      }
      .o-home--footer__top {
        min-height: 52vw;
      }
    }
    &.o-home--footer__top-layer-container,
    .o-home--footer__top-layer,
    .o-home--footer__bottom-layer {
      min-height: 725px;
    }
    .o-home--footer__bg-container {
      max-height: 725px;
    }
    .o-home--footer__dark-bg-container {
      margin-top: -2vw;
      padding-top: 0;
    }
  }
  @media (min-width: 650px) {
    &.is-last {
      &.o-home--footer__container {
        margin-top: 2vw;
      }
      .o-home--footer__top {
        min-height: 50vw;
      }
    }
    &.o-home--footer__top-layer-container,
    .o-home--footer__top-layer,
    .o-home--footer__bottom-layer {
      min-height: 700px;
    }
    .o-home--footer__bg-container {
      max-height: 700px;
    }
    .o-home--footer__dark-bg-container {
      margin-top: 0;
      padding-top: 8vw;
    }
  }
  @media ${device.sm} {
    &.is-last {
      &.o-home--footer__container {
        margin-top: 0;
      }
      .o-home--footer__top {
        min-height: 35vw;
      }
    }
    &.o-home--footer__top-layer-container,
    .o-home--footer__top-layer,
    .o-home--footer__bottom-layer {
      min-height: 680px;
    }
    .o-home--footer__bg-container {
      max-height: 680px;
    }
    .o-home--footer__dark-bg-container {
      padding-top: 2vw;
    }
  }
  @media (min-width: 850px) {
    .o-home--footer__dark-bg-container {
      padding-top: 10vw;
    }
  }
  @media ${device.md} {
    &.is-last {
      &.o-home--footer__container {
        margin-top: -5vw;
      }
      .o-home--footer__top {
        min-height: 30vw;
      }
      .o-home--footer__dark-bg-container {
        display: none;
      }
    }
    &.o-home--footer__top-layer-container,
    .o-home--footer__top-layer,
    .o-home--footer__bottom-layer {
      min-height: 750px;
    }
    .o-home--footer__bg-container {
      max-height: 750px;
    }
    .o-home--footer__dark-bg-container {
      padding-top: 10vw;
    }
  }
  @media (min-width: 1200px) {
    &.is-last {
      &.o-home--footer__top-layer-container,
      .o-home--footer__top-layer,
      .o-home--footer__bottom-layer {
        min-height: 850px;
      }
      .o-home--footer__bg-container {
        max-height: 850px;
      }
    }
    .o-home--footer__dark-bg-container {
      padding-top: 14vw;
    }
    .o-home--footer__share,
    .o-home--footer__dark-bg-container {
      background-color: transparent;
    }
  }
  @media ${device.lg} {
    &.is-last {
      &.o-home--footer__container {
        margin-top: -6vw;
      }
      .o-home--footer__top {
        min-height: 25vw;
      }
    }
    &.o-home--footer__top-layer-container,
    .o-home--footer__top-layer,
    .o-home--footer__bottom-layer {
      min-height: 750px;
    }
    .o-home--footer__bg-container {
      max-height: 750px;
    }
    .o-home--footer__dark-bg-container {
      padding-top: 16vw;
    }
  }
  @media (min-width: 1500px) {
    &.is-last {
      &.o-home--footer__container {
        margin-top: -2vw;
      }
      .o-home--footer__top {
        min-height: 20vw;
      }
      &.o-home--footer__top-layer-container,
      .o-home--footer__top-layer,
      .o-home--footer__bottom-layer {
        min-height: 900px;
      }
      .o-home--footer__bg-container {
        max-height: 900px;
      }
    }
    &.o-home--footer__top-layer-container,
    .o-home--footer__top-layer,
    .o-home--footer__bottom-layer {
      min-height: 720px;
    }
    .o-home--footer__bg-container {
      max-height: 720px;
    }
    .o-home--footer__dark-bg-container {
      padding-top: 18vw;
    }
  }
  @media (min-width: 1700px) {
    &.is-last {
      &.o-home--footer__container {
        margin-top: -2vw;
      }
      .o-home--footer__top {
        min-height: 22vw;
      }
    }
    &.o-home--footer__top-layer-container,
    .o-home--footer__top-layer,
    .o-home--footer__bottom-layer {
      min-height: 900px;
    }
    .o-home--footer__bg-container {
      max-height: 900px;
    }
    .o-home--footer__dark-bg-container {
      padding-top: 20vw;
    }
  }
  @media ${device.xl} {
    &.is-last {
      &.o-home--footer__container {
        margin-top: 2vw;
      }
      .o-home--footer__top {
        min-height: 25vw;
      }
      .o-home--footer__bg {
        top: 0;
      }
      &.o-home--footer__top-layer-container,
      .o-home--footer__top-layer,
      .o-home--footer__bottom-layer {
        min-height: 1150px;
      }
      .o-home--footer__bg-container {
        max-height: 1150px;
      }
    }
  }
`

export const HomeTopFooter = styled.div`
  position: absolute;

  .o-home--footer__top-layer {
    align-items: center;
    display: flex;
    position: absolute;
    top: 50% !important;
    transform: translateY(-50%);
    visibility: hidden;
    &.is-active {
      visibility: visible;
    }
  }
  .o-home--footer__header {
    position: relative;

    &.to-animate-zoom {
      .o-home--footer__jeepGrill {
        transform: scale(1);
      }
    }
    &.to-animate-position {
      .o-home--footer__jeepGrill {
        transform: scale(1) translateY(-60px);
        transition: transform 1s ${easeInOutExpo};
      }
    }
    &.to-animate-text {
      .o-home--footer__title {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  .o-home--footer__jeepGrill {
    height: 20px;
    margin-bottom: 36px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 60px;
    transform: scale(3);
    transition: transform 2s ${easeInOutExpo};
    width: 74px;
    z-index: 1;

    svg {
      height: auto;
      width: 100%;
    }
  }
  .o-home--footer__title {
    opacity: 0;
    transition: opacity 1.5s ${easeInOutExpo};
  }
  .o-home--footer__bg-container {
    border: 1px solid transparent;
    display: block;
    position: relative;
    width: 100vw;
  }
  .o-home--footer__bg {
    left: -25%;
    height: auto;
    margin-top: 45vw;
    position: relative;
    top: 0;
    width: 150%;
  }
  .o-home--footer__top {
    /* border: 1px solid blue; */
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 75vw;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
  }
  .o-home--footer__dark-bg-container {
    position: absolute;
  }
  .o-home--footer__dark-bg {
    height: 500px;
  }
  .o-home--footer__load-histories {
    position: relative;
    margin-top: 30px;
    width: 100%;
  }
  .o-home--footer__showMoreButton,
  .o-home--footer__loader,
  .o-home--footer__toscroll {
    height: 50px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 230px;
  }
  .o-home--footer__showMoreButton {
    cursor: pointer;
    pointer-events: auto;

    svg {
      pointer-events: none;
    }
  }

  @media (min-width: 400px) {
    .o-home--footer__bg {
      margin-top: 30vw;
      top: 0;
    }
    .o-home--footer__top {
      min-height: 65vw;
    }
  }
  @media (min-width: 550px) {
    .o-home--footer__bg {
      margin-top: 16vw;
    }
    .o-home--footer__top {
      min-height: 54vw;
    }
  }
  @media (min-width: 650px) {
    .o-home--footer__bg {
      margin-top: 10vw;
      top: 0;
    }
    .o-home--footer__top {
      min-height: 46vw;
    }
  }
  @media ${device.sm} {
    .o-home--footer__dark-bg {
      height: 300px;
    }
    .o-home--footer__header {
      &.to-animate-position {
        .o-home--footer__jeepGrill {
          transform: scale(1) translateY(-80px);
        }
      }
    }
    .o-home--footer__jeepGrill {
      margin-bottom: 40px;
      top: 80px;
    }
    .o-home--footer__bg {
      left: -10%;
      margin-top: 0;
      top: 6vw;
      width: 120%;
    }
    .o-home--footer__top {
      min-height: 35vw;
    }
    .o-home--footer__showMoreButton,
    .o-home--footer__loader,
    .o-home--footer__toscroll {
      width: 280px;
    }
  }
  @media (min-width: 850px) {
    .o-home--footer__bg {
      left: -5%;
      top: 6vw;
      width: 110%;
    }
    .o-home--footer__top {
      min-height: 30vw;
    }
  }
  @media ${device.md} {
    .o-home--footer__jeepGrill {
      height: 23px;
      width: 82px;
    }
    .o-home--footer__bg {
      left: -0.5%;
      top: 5vw;
      width: 101%;
    }
    .o-home--footer__top {
      min-height: 28vw;
    }
  }
  @media (min-width: 1200px) {
    .o-home--footer__bg {
      top: 5vw;
    }
    .o-home--footer__top {
      min-height: 30vw;
    }
  }
  @media ${device.lg} {
    .o-home--footer__jeepGrill {
      height: 25px;
      margin-bottom: 50px;
      width: 90px;
    }
    .o-home--footer__bg {
      top: -4vw;
    }
    .o-home--footer__top {
      min-height: 20vw;
    }
    .o-home--footer__dark-bg {
      height: 400px;
    }
  }
  @media (min-width: 1500px) {
    .o-home--footer__bg {
      top: -7vw;
    }
    .o-home--footer__top {
      min-height: 18vw;
    }
  }
  @media (min-width: 1700px) {
    .o-home--footer__header {
      &.to-animate-position {
        .o-home--footer__jeepGrill {
          transform: scale(1) translateY(-115px);
        }
      }
    }
    .o-home--footer__jeepGrill {
      height: 36px;
      margin-bottom: 60px;
      top: 115px;
      width: 120px;
    }
    .o-home--footer__bg {
      top: -4vw;
    }
    .o-home--footer__top {
      min-height: 21vw;
    }
    .o-home--footer__showMoreButton,
    .o-home--footer__loader,
    .o-home--footer__toscroll {
      height: 60px;
      width: 312px;
    }
  }
`

export const TitleBlock = styled.h4`
  font-family: ${props => props.theme.vars.avenirNext};
  font-weight: ${props => props.theme.vars.fontBold};
  font-size: 34px;
  line-height: 1em;
  color: ${props => props.theme.vars.black};
  text-transform: uppercase;

  @media ${device.sm} {
    font-size: 40px;
    line-height: 44px;
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
  }
  @media ${device.md} {
    font-size: 50px;
    line-height: 54px;
    max-width: 650px;
  }
  @media ${device.lg} {
    font-size: 60px;
    line-height: 64px;
    max-width: 800px;
  }
  @media (min-width: 1700px) {
    font-size: 72px;
    line-height: 76px;
    max-width: 940px;
  }
`
