import React, { useRef, useEffect } from "react"
import { shape, string, number } from "prop-types"
import { Col, Row, Container } from "react-awesome-styled-grid"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import gsap from "gsap"

import { isIOS } from "../../../../helpers/platform"
import useNearScreen from "../../../../hooks/useNearScreen"
import useWindowSize from "../../../../hooks/useWindowSize"
import BgBlock from "../../../elements/bgBlock/BgBlock"
import Image from "../../../elements/image/Image"
import SvgStar from "../../../elements/svgs/SvgStar"
import { CoverContainer, TextContainer, TitleContainer, QuoteContainer, DescriptionContainer, BlockInside, ImageContainer } from "./coverLegacy.styled"

const CoverLegacy = ({ data, index, className }) => {
  const classNameID = `c-cover-legacy`
  const { element, show } = useNearScreen({ threshold: 0.1, onlyShow: true })
  const windowSize = useWindowSize()
  const baseTrigger = useRef(null)
  const tl = useRef(null)

  const setAnimations = () => {
    gsap.utils.toArray(`.${classNameID} .item-parallax`).forEach(layer => {
      const theDepth = Number(layer.dataset.depth)
      // const movement = layer.offsetHeight * theDepth
      const movement = 300 * theDepth
      tl.current.fromTo(layer, { y: movement }, { y: -movement, ease: "none" }, 0)
    })
  }

  const initAnimations = () => {
    // console.log('set initial animations');
    if (!isIOS()) {
      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: baseTrigger.current,
          start: "50% 105%",
          end: "50% -5%",
          scrub: 0.5,
          toggleClass: "is-active",
          // markers: true,
          // id: 'history',
        },
      })
      setAnimations()
    }
  }

  useEffect(() => {
    initAnimations()

    return () => {
      if (tl.current) {
        tl.current.clear()
        tl.current.kill()
        tl.current = null
      }
    }
  }, [])

  useEffect(() => {
    let timer = 0
    if (windowSize.width !== 0) {
      ScrollTrigger.refresh()
      timer = setTimeout(() => {
        ScrollTrigger.refresh()
      }, 300)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [windowSize.width])

  return (
    <CoverContainer className={`${classNameID} ${classNameID}_${index} ${className}`}>
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          // border: '1px solid red',
          // zIndex: 1,
        }}
        ref={baseTrigger}
        className={`${classNameID}__trigger`}
      />
      <BgBlock depth={0} color={data.color}>
        <BlockInside isIOS={isIOS()}>
          <Container>
            <Row>
              <Col xs={4} sm={8} md={10} lg={10} xl={10} offset={{ xs: 0, sm: 0, md: 1, lg: 1, xl: 1 }}>
                <TextContainer>
                  <div className={`title-zoom ${show ? "is-zoom-out" : ""}`} ref={element}>
                    <TitleContainer className="title item-parallax" data-depth={0.7}>
                      {data.model}
                    </TitleContainer>
                  </div>
                  <QuoteContainer className="item-parallax" data-depth={0.72}>
                    <span className={`${classNameID}__special-badge`}>
                      <SvgStar className={`${classNameID}__special-badge-img`} />
                    </span>
                    <p className={`${classNameID}__quote`}>{`${data.year} Jeep`}</p>
                  </QuoteContainer>
                  <ImageContainer className="item-parallax" data-depth={0.5} isIOS={isIOS()}>
                    <div className={`${classNameID}__image image-zoom ${show ? "is-zoom-in" : ""}`}>
                      <Image baseName={data.image.split("/").pop()} alt={`${data.year} ${data.model}`} />
                    </div>
                  </ImageContainer>
                  <DescriptionContainer className="item-parallax" data-depth={0.74} dangerouslySetInnerHTML={{ __html: data.desc }} />
                </TextContainer>
              </Col>
            </Row>
          </Container>
        </BlockInside>
      </BgBlock>
    </CoverContainer>
  )
}

CoverLegacy.propTypes = {
  data: shape({}),
  index: number,
  className: string,
}

CoverLegacy.defaultProps = {
  data: null,
  index: 0,
  className: "",
}

export default React.memo(CoverLegacy)
