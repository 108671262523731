import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

const StickyAddStoryContainer = styled.div`
  bottom: 10%;
  position: fixed;
  right: 10px;
  z-index: 5;

  .o-sticky-add-story__container {
    position: relative;
  }
  .o-sticky-add-story__text-container {
    display: none;
    pointer-events: none;
    position: relative;
    height: 210px;
    margin-bottom: 18px;
  }
  .o-sticky-add-story__text {
    color: ${props => props.theme.vars.charade};
    font-family: ${props => props.theme.vars.tradeGot};
    font-size: 14px;
    font-weight: ${props => props.theme.vars.fontBold};
    height: 14px;
    letter-spacing: 2px;
    line-height: 1em;
    left: 50%;
    position: absolute;
    text-shadow: 0px 0px 2px rgb(255 255 255 / 100%);
    text-transform: uppercase;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    width: 210px;
  }

  @media ${device.sm} {
    bottom: 20%;
    .o-sticky-add-story__text-container {
      display: block;
    }
  }
  @media ${device.md} {
    bottom: 15%;
    .o-sticky-add-story__text-container {
      margin-bottom: 20px;
    }
  }
  @media ${device.lg} {
    right: 36px;
  }
  @media ${device.xl} {
    bottom: 18%;
    right: 80px;
    .o-sticky-add-story__text-container {
      margin-bottom: 24px;
    }
    .o-sticky-add-story__text {
      font-size: 16px;
    }
  }
`
export default StickyAddStoryContainer
