import React, { useEffect, useState, useRef, useContext } from "react"
import { withPrefix } from "gatsby"
import { shape, number } from "prop-types"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import gsap from "gsap"
import lottie from "lottie-web"

import useNearScreen from "../../../../hooks/useNearScreen"
import HistoriesContext from "../../../../context/histories.context"
import useWindowSize from "../../../../hooks/useWindowSize"
import { isIOS } from "../../../../helpers/platform"
import BgBlock from "../../../elements/bgBlock/BgBlock"
import SvgJeepGrill from "../../../elements/svgs/SvgJeepGrill"
import Image from "../../../elements/image/Image"
// import SvgArrowDown from '../../../elements/svgs/SvgArrowDown'
// import { StyledHomeFooterTop, StyledHomeFooterBottom, HomeTopFooter, ContentFooter, TitleBlock, TitleShare, SubtitleShare, DescriptionShare } from "./HomeFooter.style"
import { StyledHomeFooterTop, HomeTopFooter, TitleBlock } from "./HomeFooter.style"

const HomeFooter = ({ data, index, totalPages }) => {
  const classNameID = `o-home--footer`
  const { element, show } = useNearScreen({ threshold: 0.9, onlyShow: true })
  const [toLoadData, setToLoadData] = useState(false)
  const [viewConfig, setViewConfig] = useState({ isReady: false, isFixed: false, showLoader: false, showArrow: true, showButton: true, isLastFooter: false })
  const windowSize = useWindowSize()
  const histories = useContext(HistoriesContext)
  const container = useRef(null)
  const layerContainer = useRef(null)
  const bottomContainer = useRef(null)
  const bgContainer = useRef(null)
  const tl = useRef(null)
  const tlBottom = useRef(null)
  const path = `${withPrefix("/")}assets/animations/`
  const buttonLottie = useRef(null)
  const buttonContainer = useRef(null)
  const loaderLottie = useRef(null)
  const loaderContainer = useRef(null)
  const arrowLottie = useRef(null)
  const arrowContainer = useRef(null)

  const removeLotties = () => {
    if (buttonLottie.current) {
      buttonLottie.current.stop()
      buttonLottie.current.destroy()
      buttonLottie.current = null
    }
    if (loaderLottie.current) {
      loaderLottie.current.stop()
      loaderLottie.current.destroy()
      loaderLottie.current = null
    }
    if (arrowLottie.current) {
      arrowLottie.current.stop()
      arrowLottie.current.destroy()
      arrowLottie.current = null
    }
  }

  // Lotties
  const addLotties = () => {
    removeLotties()
    if (buttonContainer.current) {
      buttonLottie.current = lottie.loadAnimation({
        container: buttonContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: `${path}load_stories_button/data.json`,
      })
    }
    if (loaderContainer.current) {
      loaderLottie.current = lottie.loadAnimation({
        container: loaderContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: `${path}loader_stories/data.json`,
      })
    }
    if (arrowContainer.current) {
      arrowLottie.current = lottie.loadAnimation({
        container: arrowContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: `${path}arrow_stories/data.json`,
      })
    }
  }

  const removeScrollTriggers = () => {
    if (tl.current) {
      tl.current.clear()
      tl.current.kill()
      tl.current = null
    }
    if (tlBottom.current) {
      tlBottom.current.clear()
      tlBottom.current.kill()
      tlBottom.current = null
    }
  }

  const addScrollTriggers = () => {
    removeScrollTriggers()
    if (container.current) container.current.classList.remove("no-fixed")

    if (layerContainer.current && tl.current === null) {
      // const endData = viewConfig.showButton ? '100% 50%' : '110% 0%' // '100% 50%' '110% 0%'
      tl.current = gsap.timeline({})
      tl.current.to(layerContainer.current, {
        scrollTrigger: {
          trigger: layerContainer.current,
          start: "0% 100%",
          end: "110% 0%",
          pinSpacing: false,
          pin: true,
          scrub: true,
          toggleClass: "is-active",
          // markers: true,
          // id: 'homeFooter',
        },
      })
    }
    // if (viewConfig.showButton && bottomContainer.current && tlBottom.current === null) {
    //   tlBottom.current = gsap.timeline({})
    //   tlBottom.current.to(bottomContainer.current, {
    //     scrollTrigger: {
    //       trigger: bottomContainer.current,
    //       start: '50% 50%',
    //       end: '100% 0%',
    //       pinSpacing: false,
    //       pin: true,
    //       scrub: true,
    //       onToggle: ({ isActive }) => {
    //         if (isActive) {
    //           container.current.classList.add('no-fixed')
    //         } else {
    //           container.current.classList.remove('no-fixed')
    //         }
    //       },
    //       // markers: true,
    //       // id: 'homeBottomFooter',
    //     },
    //   })
    // }
  }

  const handleShowMore = () => {
    removeLotties()
    const config = { showButton: false, showLoader: true }
    setViewConfig({ ...viewConfig, ...config })
    setToLoadData(true)
  }

  const getTopContent = () => {
    return (
      <>
        <div className={`${classNameID}__top`}>
          <Container>
            <Row>
              <Col xs={4} sm={8} md={12}>
                <div className={`${classNameID}__header`} ref={element}>
                  <div className={`${classNameID}__jeepGrill`}>
                    <SvgJeepGrill />
                  </div>
                  <TitleBlock className={`${classNameID}__title`}>{data.title}</TitleBlock>
                </div>
                <div className={`${classNameID}__load-histories`}>
                  {viewConfig.showButton && <div ref={buttonContainer} onClick={handleShowMore} role="button" tabIndex={0} onKeyPress={null} label="Cargar más historias" className={`${classNameID}__showMoreButton tag-historias-cargar-mas`} />}
                  {viewConfig.showLoader && <div ref={loaderContainer} className={`${classNameID}__loader`} />}
                  {viewConfig.showArrow && <div ref={arrowContainer} className={`${classNameID}__toscroll`} />}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Image baseName={data.bgImage.split("/").pop()} alt={data.title} className={`${classNameID}__bg`} />
      </>
    )
  }

  useEffect(() => {
    addLotties()

    return () => {
      removeLotties()
    }
  }, [viewConfig.showLoader, viewConfig.showButton, viewConfig.showArrow])

  useEffect(() => {
    if (viewConfig.isReady) {
      addScrollTriggers()
    }

    return () => {
      removeScrollTriggers()
    }
  }, [viewConfig.isReady])

  useEffect(() => {
    if (tl.current) {
      ScrollTrigger.refresh()
    }
  }, [windowSize.width, index, tl.current])

  useEffect(() => {
    // console.log(' > HomeFooter currentPage', histories.currentPage, 'index + 1', index + 1, 'totalPages', totalPages)
    const page = index + 1
    const config = { isReady: true, isFixed: false, showLoader: false, showButton: false, showArrow: false, isLastFooter: false }
    if (histories.currentPage === page) {
      removeLotties()
    }
    if (histories.currentPage === page) {
      config.showButton = true
    }
    if (page !== totalPages) {
      config.isFixed = true
    }
    if (!config.showButton && !config.showLoader) {
      config.showArrow = true
    }
    if (histories.currentPage === totalPages && histories.currentPage === page) {
      config.isLastFooter = true
      config.showButton = false
      config.showArrow = false
    }
    if (isIOS()) {
      if (histories.currentPage === page) {
        config.isLastFooter = true
        config.isFixed = false
      } else {
        config.isFixed = true
      }
    }
    // console.log(histories.currentPage === page, index, data.title, histories.currentPage, totalPages, config)
    setViewConfig({ ...viewConfig, ...config })
  }, [histories.currentPage])

  useEffect(() => {
    let timer
    if (toLoadData) {
      timer = setTimeout(() => {
        histories.handleShowMore()
      }, 1000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [toLoadData])

  useEffect(() => {
    let timerZoom
    let timerPosition
    let timerText
    if (show) {
      timerZoom = setTimeout(() => {
        element.current.classList.add("to-animate-zoom")
      }, 200)
      timerPosition = setTimeout(() => {
        element.current.classList.add("to-animate-position")
      }, 1750)
      timerText = setTimeout(() => {
        element.current.classList.add("to-animate-text")
      }, 1950)
    }
    return () => {
      clearTimeout(timerZoom)
      clearTimeout(timerPosition)
      clearTimeout(timerText)
    }
  }, [show])

  return (
    <>
      {viewConfig.isReady && (
        <>
          {viewConfig.isFixed ? (
            <StyledHomeFooterTop ref={container} className={`${classNameID}__container`}>
              <HomeTopFooter className={`${classNameID}__top-layer-container`}>
                <div ref={layerContainer} className={`${classNameID}__top-layer`}>
                  <div className={`${classNameID}__top-layer-inner`}>
                    <div className={`${classNameID}__bg-container`} ref={bgContainer}>
                      {getTopContent()}
                    </div>
                    <BgBlock type="hideBottom" theme="dark" depth={0} className={`${classNameID}__dark-bg-container`}>
                      <div className={`${classNameID}__dark-bg`} />
                    </BgBlock>
                  </div>
                </div>
              </HomeTopFooter>
              <div ref={bottomContainer} className={`${classNameID}__bottom-layer`} />
            </StyledHomeFooterTop>
          ) : (
            <StyledHomeFooterTop className={`${classNameID}__container ${viewConfig.isLastFooter ? "is-last" : ""}`}>
              <HomeTopFooter className={`${classNameID}__top-layer-container`}>
                <div className={`${classNameID}__top-layer`}>
                  <div className={`${classNameID}__top-layer-inner`}>
                    <div className={`${classNameID}__bg-container`}>{getTopContent()}</div>
                    <BgBlock type="hideBottom" theme="dark" depth={0} className={`${classNameID}__dark-bg-container`}>
                      <div className={`${classNameID}__dark-bg`} />
                    </BgBlock>
                  </div>
                </div>
              </HomeTopFooter>
              <div className={`${classNameID}__bottom-layer`} />
            </StyledHomeFooterTop>
          )}
        </>
      )}
    </>
  )
}

HomeFooter.propTypes = {
  data: shape({}),
  index: number,
  totalPages: number,
}

HomeFooter.defaultProps = {
  data: null,
  index: 0,
  totalPages: 0,
}

const propsAreEqual = (prevData, nextData) => {
  // console.log(`   > HomeFooter propsAreEqual`, prevData, nextData)
  return prevData.index === nextData.index
}
export default React.memo(HomeFooter, propsAreEqual)
