import React, { useEffect, useRef } from "react"
import { withPrefix } from "gatsby"
import lottie from "lottie-web"

import useNearScreen from "../../../../hooks/useNearScreen"
import StyledHomeCover from "./HomeCover.style"

const HomeCover = ({ className = "" }) => {
  const classNameID = `o-home-cover`
  const { element, show } = useNearScreen({ threshold: 0.2, onlyShow: false })
  const path = `${withPrefix("/")}assets/animations/`
  const bgContainer = useRef(null)
  const coverContainer = useRef(null)
  const coverTitleContainer = useRef(null)
  const coverLottie = useRef(null)
  const coverTitleLottie = useRef(null)

  // Lotties
  const addLotties = () => {
    coverLottie.current = lottie.loadAnimation({
      container: coverContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: false,
      path: `${path}cover/data.json`,
    })

    coverTitleLottie.current = lottie.loadAnimation({
      container: coverTitleContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: `${path}cover_title/data.json`,
    })
  }

  useEffect(() => {
    addLotties()

    const interval = setInterval(() => {
      if (coverLottie.current.currentFrame !== 0) {
        const ratio = coverLottie.current.currentFrame / coverLottie.current.totalFrames
        if (ratio > 0.05) {
          clearInterval(interval)
          coverTitleContainer.current.classList.add("is-visible")
          coverTitleLottie.current.play()
        }
      }
    }, 10)

    const timeout = setTimeout(() => {
      bgContainer.current.classList.add("is-zoom-out")
    }, 100)

    return () => {
      clearInterval(interval)
      clearInterval(timeout)

      if (coverLottie.current) {
        coverLottie.current.stop()
        coverLottie.current.destroy()
        coverLottie.current = null
      }
      if (coverTitleLottie.current) {
        coverTitleLottie.current.stop()
        coverTitleLottie.current.destroy()
        coverTitleLottie.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (coverLottie.current) {
      if (show) {
        coverLottie.current.play()
      } else {
        coverLottie.current.pause()
      }
    }
  }, [show])

  return (
    <StyledHomeCover className={`${classNameID} ${className}`} ref={element}>
      <div className={`${classNameID}__bg`} ref={bgContainer}>
        <div className={`${classNameID}__gradient`} />
        <div ref={coverContainer} />
      </div>
      <div className={`${classNameID}__title`} ref={coverTitleContainer} />
    </StyledHomeCover>
  )
}

export default HomeCover
