import * as React from "react"

function SvgStar({ width = "39.5", height = "39.5", className = "" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x={0} y={0} width={width} height={height} className={className} viewBox="0 0 39.5 39.5" xmlSpace="preserve">
      <path d="M38.7 14.2l-3.5 2.5c.2 1 .3 2 .3 3.1 0 4.6-2 8.7-5.1 11.5l1.3 4.1c4.7-3.6 7.7-9.3 7.7-15.6.1-2-.2-3.9-.7-5.6zM34 13.2h4.3C35.7 5.7 28.6.3 20.3 0l1.3 4.1c5.5.7 10.2 4.2 12.4 9.1zm-4.7-8.7v.8s-.9-1.1-1-1.3 1 .5 1 .5zM17.9 4.1L19.2 0c-8.3.3-15.4 5.7-18 13.2h4.3c2.2-4.9 6.9-8.4 12.4-9.1zM8.5 5.9c0-.6.9-.8 1.1-.6.1.1 0 .7 0 .7l-1.1.4v-.5zM19.8 35.5c-1.5 0-3-.2-4.4-.6-.5.3-1.1.6-1.3.6 0 0-.2-.7 0-.9l.1-.1c-.8-.3-1.5-.6-2.2-1L8.6 36c3.2 2.2 7 3.5 11.2 3.5 4.1 0 8-1.3 11.2-3.5l-3.5-2.5c-2.3 1.3-5 2-7.7 2zM4 19.8c0-1.1.1-2.1.3-3.1L.8 14.2c-.5 1.7-.8 3.6-.8 5.6 0 2.9.6 5.7 1.8 8.1.1-.2.1-.3.2-.3.4-.1 1.1 1.6 1.1 1.6s.9.3.9.6c.1.3-.3.9-.5 1.3 1.2 1.7 2.6 3.1 4.2 4.4L9 31.4c-3-3-5-7.1-5-11.6zm-2.3 7.5c-.1 0 0-.4 0-.4s0-.2.1-.2.2.3.2.3-.2.3-.3.3z" />
      <path d="M38.5 13.7l-14.4.1L19.8 0l-4.4 13.7h-3.8c-.2.1-.5.4-.6.5-.1.3-.9.5-1 .2-.1-.3-.2-.6-.3-.7H.9l6.2 4.4.1-.1c.2-.1.4.1.4.5l5 3.6-4.5 13.6 11.6-8.5 11.6 8.5L26.8 22l11.7-8.3zm-23.1 8.1c.1.2 0 .4 0 .4s-.2-.2-.2-.3c.1 0 .2-.1.2-.1zm-1 1.6s-.5-.5-.5-.7c.1-.3 1.1-.7 1.1-.7s.7.6.6.8c0 .4-.5.4-1.2.6zm1.5-2.9c-.1-.1 0-.6 0-.6s0-.3.2-.3.3.4.3.4-.3.6-.5.5zm15.5-2.4s-.5.4-.8.4l-.5-.9 1.3.5zm-7.1 9.8s-.2-.2-.2-.3c0 0 .1-.1.2-.1.1.2 0 .4 0 .4zm.6-.4c-.6 0-1-.5-1-.5h1s.2.5 0 .5zm0-6.6c-.3-.3-.7-.1-.7-.1l.7-.8s.2 1.1 0 .9zm1.1.3c0-.2 1.4.6 1.1.6-.5.1-.9.5-1.1.5v-1.1z" />
    </svg>
  )
}

export default SvgStar
