import { useEffect, useState, useRef } from "react"

function useNearScreen({ threshold = 0.5, onlyShow = true }) {
  const element = useRef(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    Promise.resolve(typeof window.IntersectionObserver !== "undefined" ? window.IntersectionObserver : import("intersection-observer")).then(() => {
      const observer = new window.IntersectionObserver(
        entries => {
          const { isIntersecting } = entries[0]
          if (onlyShow) {
            if (isIntersecting) setShow(true)
            return
          }
          if (isIntersecting) setShow(true)
          else setShow(false)
        },
        { threshold }
      )
      observer.observe(element.current)
    })
  }, [element])

  return { show, element }
}

export default useNearScreen
