import React from "react"

import historyData from "../../../data/dataHistory"
import footerData from "../../../data/dataFooter"
import StoriesPage, { StoriesPageType } from "./storiesPage"
import CoverLegacy from "../home/legacy/coverLegacy"
import CoverShare from "../home/share/coverShare"
import HomeFooter from "../home/footer/HomeFooter"
import { StyledStoriesPages } from "./StoriesPages.style"
import { shuffle } from "../../../helpers/array"

const arraySuffleHeritage = shuffle(historyData)
const arraySuffleFooter = shuffle(footerData)

const PageContainer = ({ index, stories, totalPages }) => {
  const classNameID = `o-home-pages`

  // useEffect(() => {
  //   console.log("> PageContainer", index, "totalPages", totalPages, "chunkStories", stories.length)
  // }, [])

  return (
    <>
      <StyledStoriesPages className={`${classNameID} ${classNameID}__top`}>
        <StoriesPage stories={stories} type={StoriesPageType.top} pagenumber={index} />
      </StyledStoriesPages>

      <CoverLegacy data={arraySuffleHeritage[index % arraySuffleHeritage.length]} index={index} />

      <StyledStoriesPages className={`${classNameID} ${classNameID}__middle`}>
        <StoriesPage stories={stories} type={StoriesPageType.middle} pagenumber={index} />
      </StyledStoriesPages>

      <CoverShare index={index} />

      <StyledStoriesPages className={`${classNameID} ${classNameID}__bottom`}>
        <StoriesPage stories={stories} type={StoriesPageType.bottom} pagenumber={index} />
      </StyledStoriesPages>

      <HomeFooter data={arraySuffleFooter[index % arraySuffleFooter.length]} index={index} totalPages={totalPages} />
    </>
  )
}

const propsAreEqual = (prevStory, nextStory) => {
  // console.log(`> PageContainer propsAreEqual`, prevStory, nextStory)
  return prevStory.index === nextStory.index
}
export default React.memo(PageContainer, propsAreEqual)
