// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_chunk

// Creates an array of elements split into groups the length of size.
// chunk(['a', 'b', 'c', 'd'], 2);
// Output: [['a', 'b'], ['c', 'd']]
export const chunk = (input, size) => {
  return input.reduce((arr, item, idx) => {
    return idx % size === 0 ? [...arr, [item]] : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]]
  }, [])
}

export const shuffle = array => {
  return array.sort(() => Math.random() - 0.5)
}
