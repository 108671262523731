import React from "react"

// import AnniversaryLogo from "./HomeAnniversaryLogo"
import jeepstoriesBadge from "../../../../../static/assets/svg/home/jeepstories_badge.svg"
import SvgArrowDown from "../../../elements/svgs/SvgArrowDown"
import StyledHomeAnniversary from "./HomeAnniversary.style"

const HomeAnniversary = ({ className = "" }) => {
  const classNameID = `o-home-storytelling`

  return (
    <StyledHomeAnniversary className={`${classNameID} ${className}`}>
      <div className={`${classNameID}__logocontainer`}>
        <div className={`${classNameID}__logo`}>
          <img className={`${classNameID}__badge`} src={jeepstoriesBadge} alt="" />
        </div>
        <div className={`${classNameID}__logodescription`}>
          <p>Durante 80 años te has convertido en parte de nuestro legado al crear un sinfín de historias, llegar a los lugares menos imaginados y acompañarte en los mejores momentos. Hoy es momento de conocer algunas de estas historias. Descubre JEEPSTORIES</p>
        </div>
        <div className={`${classNameID}__toscroll`}>
          <SvgArrowDown />
        </div>
      </div>
    </StyledHomeAnniversary>
  )
}

export default HomeAnniversary
