import { useState } from "react"
import { getStories } from "../api/api"

const useHistoriesData = () => {
  const [stories, setStories] = useState({ items: [], totalPages: 0 })
  const [currentPage, setCurrentPage] = useState(1)

  const fetchData = () => {
    const url = `${process.env.GATSBY_API_PAGE_URL}?page=${currentPage}&page_size=14`
    // const url = 'http://threetest.local/getPage.php?page=${currentPage}&page_size=14'

    getStories(url, response => {
      // setStories({ items: [...stories.items, ...response.data], totalPages: response.pagination.lastPage })
      setStories({ allItems: [...stories.items, ...response.data], items: response.data, totalPages: response.pagination.lastPage })
    })
  }

  const fetchDataByCategory = category => {
    const url = `${process.env.GATSBY_API_PAGE_URL}?section=${category}&page=${currentPage}&page_size=14`
    // const url = 'http://threetest.local/getPage.php?section=${category}&page=${currentPage}&page_size=14'

    getStories(url, response => {
      setStories({ allItems: [...stories.items, ...response.data], items: response.data, totalPages: response.pagination.lastPage })
    })
  }

  const handleShowMore = () => {
    setCurrentPage(currentPage + 1)
  }

  return { currentPage, stories, fetchDataByCategory, fetchData, handleShowMore }
}

export default useHistoriesData
