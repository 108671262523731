import styled from "styled-components"
import { device } from "../../../../theme/mediaQueries"
import easeInOutExpo from "../../../../theme/easings"

const StyledHomeVideo = styled.div`
  display: block;
  pointer-events: none;
  position: relative;
  z-index: 10;

  .o-home-video__stage {
    position: relative;
    width: 300px;
    margin: 0 auto 0;
    z-index: 3;
    @media ${device.sm} {
      width: 650px;
    }
    @media ${device.md} {
      width: 910px;
    }
    @media ${device.lg} {
      width: 1045px;
    }
    @media ${device.xl} {
      width: 1365px;
    }
  }

  .o-home-video__trigger {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 70vh;
    /* background-color: yellow; */
    @media ${device.md} {
      height: 100vh;
    }
  }

  .o-home-video__video {
    display: block;
    height: 170px;
    margin-top: 55px;
    pointer-events: auto;
    width: 100%;

    @media ${device.sm} {
      height: 370px;
    }
    @media ${device.md} {
      height: 520px;
      margin-top: 178px;
    }
    @media ${device.lg} {
      height: 585px;
      margin-top: 238px;
    }
    @media ${device.xl} {
      height: 767px;
      margin-top: 393px;
    }
  }

  .o-home-video__container {
    background-color: gray;
    height: 100%;
    position: relative;
    transform: scale(0.8);
    transition: transform 3s ${easeInOutExpo};
    width: 100%;

    &.is-zoom-in {
      transform: scale(1);
    }
  }
`

export default StyledHomeVideo
