import React, { useEffect, useContext } from "react"

import HistoriesContext from "../../../context/histories.context"
import StoriesPages from "../../modules/storiesPages/StoriesPages"
import HomeAnniversary from "../../modules/home/anniversary/HomeAnniversary"
import HomeCover from "../../modules/home/cover/HomeCover"
import HomeIntro from "../../modules/home/intro/HomeIntro"
import HomeVideo from "../../modules/home/video/HomeVideo"
import StickyAddStory from "../../modules/stickyAddHistory/StickyAddStory"

import { HomeContainer } from "./Home.style"

const Home = ({ category, hideHeader = false }) => {
  const classNameID = "o-home"
  const histories = useContext(HistoriesContext)

  useEffect(() => {
    if (category) histories.fetchDataByCategory(category)
    else histories.fetchData()
    // console.log("Home", histories.currentPage, histories.stories)
  }, [histories.currentPage])

  return (
    <HomeContainer className={`${classNameID}`}>
      <StickyAddStory />
      {!hideHeader && (
        <>
          <HomeCover />
          <HomeIntro />
          <HomeVideo />
          <HomeAnniversary />
        </>
      )}
      {histories.stories.items.length !== 0 && <StoriesPages items={histories.stories.items} totalPages={histories.stories.totalPages} />}
    </HomeContainer>
  )
}

export default React.memo(Home)
