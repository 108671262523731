import styled from "styled-components"
import { device } from "../../../../theme/mediaQueries"
import easeInOutExpo from "../../../../theme/easings"

export const StyledHomeIntro = styled.div`
  display: block;
  pointer-events: none;
  position: relative;
  z-index: 10;

  .c-bg-block__trigger {
    top: 60vh !important;
  }
  .o-home-intro__stage {
    /* border: 1px solid red; */
    display: block;
    margin: 0 auto;
    max-width: 500px;
    position: relative;
    padding-bottom: 20%;
    width: calc(100% - 20px);
  }
  .o-home-intro__rational {
    width: 100%;
    font-family: ${props => props.theme.vars.tradeGot};
    font-size: 12px;
    font-weight: ${props => props.theme.vars.fontBold};
    line-height: 1em;
    text-transform: uppercase;
    color: ${props => props.theme.vars.white};
    margin-top: 17px;
    text-align: center;
    opacity: 0.8;
  }
  .o-home-intro__description {
    color: ${props => props.theme.vars.white};
    font-family: ${props => props.theme.vars.openSans};
    font-size: 14px;
    font-weight: ${props => props.theme.vars.fontRegular};
    line-height: 20px;
    opacity: 0.5;
    text-align: center;
    width: 100%;
    p {
      margin-top: 20px;
    }
  }
  .o-home-intro__arrow-svg {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    svg {
      fill: ${props => props.theme.vars.mustard};
      height: 18px;
      width: 18px;
    }
  }

  @media (min-width: 530px) {
    .o-home-intro__stage {
      padding-bottom: 15%;
    }
  }
  @media (min-width: 650px) {
    .o-home-intro__stage {
      top: -50px;
    }
  }
  @media ${device.sm} {
    margin-bottom: -10%;
    margin-top: 8%;

    .o-home-intro__stage {
      max-width: unset;
      padding-bottom: 15%;
      width: 650px;
    }
    .o-home-intro__rational {
      font-size: 16px;
      margin-top: 30px;
    }
    .o-home-intro__description {
      font-size: 18px;
      line-height: 30px;
      margin-top: 30px;
    }
    .o-home-intro__arrow-svg {
      margin-top: 45px;
      svg {
        height: 22px;
        width: 22px;
      }
    }
  }
  @media ${device.md} {
    margin-top: 4%;

    .o-home-intro__stage {
      padding-bottom: 8%;
      width: 910px;
    }
    .o-home-intro__rational {
      margin-top: 45px;
    }
    .o-home-intro__description {
      margin-left: auto;
      margin-right: auto;
      width: 780px;
    }
    .o-home-intro__arrow-svg {
      margin-top: 55px;
    }
  }
  @media ${device.lg} {
    margin-bottom: -14%;
    margin-top: 5%;

    .o-home-intro__stage {
      padding-bottom: 6%;
      width: 1100px;
    }
    .o-home-intro__rational {
      font-size: 18px;
      margin-top: 75px;
    }
    .o-home-intro__description {
      font-size: 22px;
      line-height: 36px;
      margin-top: 40px;
      width: 100%;
    }
    .o-home-intro__arrow-svg {
      margin-top: 95px;
    }
  }
  @media (min-width: 1600px) {
    margin-bottom: -16%;
    margin-top: 6%;

    .o-home-intro__stage {
      padding-bottom: 0;
      top: -200px;
      width: 1190px;
    }
    .o-home-intro__rational {
      font-size: 20px;
      margin-top: 45px;
    }
    .o-home-intro__description {
      font-size: 24px;
      line-height: 36px;
      margin-top: 55px;
      p {
        margin-top: 30px;
      }
    }
  }
`

export const StyledHomeLogo = styled.div`
  position: relative;
  transform: scale(2);
  transition: transform 2s ${easeInOutExpo};
  &.is-visible {
    transform: scale(1);
  }

  .o-home-intro__plate {
    width: 80px;
    height: 35px;
    margin: 0 auto;
    display: block;
    z-index: 3;
  }

  @media ${device.sm} {
    .o-home-intro__plate {
      width: 125px;
      height: 50px;
    }
  }
  @media ${device.lg} {
    transform: scale(1.5);

    .o-home-intro__plate {
      width: 155px;
      height: 60px;
    }
  }
  @media (min-width: 1600px) {
    .o-home-intro__plate {
      width: 230px;
      height: 90px;
    }
  }
`

export const StyledHomeIntroTag = styled.div`
  display: block;
  margin: 32px auto 0;
  max-width: 350px;
  width: 100%;

  @media ${device.sm} {
    margin-top: 45px;
    max-width: 400px;
  }
  @media ${device.md} {
    margin-top: 55px;
    max-width: 450px;
  }
  @media ${device.lg} {
    margin-top: 65px;
    max-width: 500px;
  }
  @media (min-width: 1600px) {
    margin-top: 75px;
    max-width: 550px;
  }
`
