const historyData = [
  {
    id: 1,
    model: "Willys CJ",
    year: "1941",
    color: "#665d42",
    desc: "<strong>#Jeep Willys CJ</strong> fue el primer automóvil civil con tracción en las cuatro llantas. Las letras <strong>CJ</strong> tenían como significado “Civil Jeep”. Tenía ejes rígidos, su diseño era de punta cónica, contaba con un parabrisas plegable y podían conducirse sin puertas.",
    image: "/assets/images/home/legado/cars/Jeep_1941_Willys_CJ.png",
  },
  {
    id: 2,
    model: "Willys Wagon",
    year: "1946",
    color: "#474c41",
    desc: '<strong>Willys Wagon</strong> se convirtió en el precursor de Grand Cherokee. <strong>#Jeep Station Wagon</strong> tenía una pintura de tres tonos que simula el aspecto "Woodie", que para su época fue la sensación por ser un vehículo sin igual en la categoría.',
    image: "/assets/images/home/legado/cars/Jeep_1946_Willys_Wagon.png",
  },
  {
    id: 3,
    model: "CJ-3B",
    year: "1953",
    color: "#363640",
    desc: "El <strong>CJ-3B</strong> se convirtió en el #Jeep militar utilizado principalmente para la exportación. Equipado con opciones de servicio pesado disponibles comercialmente, como llantas y resortes más grandes, agregando luces de oscurecimiento. Una verdadera leyenda, equipada para trabajo duro.",
    image: "/assets/images/home/legado/cars/Jeep_1953_CJ-3B.png",
  },
  {
    id: 4,
    model: "CJ-5",
    year: "1954",
    color: "#50443b",
    desc: '<strong>#Jeep CJ-5</strong> tuvo el honor de ser un modelo difícil de derrotar, gracias a la capacidad de su motor y sus HP de potencia que con los años fueron mejorando, haciéndolo un vehículo de mucho aguante. Su Sistema de llantas "Trac-Lok" se reemplazó al "Power-Lok" una serie de años después.',
    image: "/assets/images/home/legado/cars/Jeep_1954_CJ-5.png",
  },
  {
    id: 5,
    model: "Willys CJ-6",
    year: "1955",
    color: "#4c4c33",
    desc: "<strong>#Jeep CJ-6</strong> debutó en 1955. Era una versión más grande que CJ-5, su chasis extendido permitió la adición de una segunda fila de asientos. El Servicio Forestal de Estados Unidos fueron los que más pusieron en uso ese modelo.",
    image: "/assets/images/home/legado/cars/Jeep_1944_Willys_CJ-6.png",
  },
  {
    id: 6,
    model: "Grand Wagoneer",
    year: "1962",
    color: "#665d42",
    desc: "<strong>#Jeep Wagoneer</strong> fue considerado el padre de todos los SUV 4x4 de lujo, diseñado para brindar estilo y comodidad a ti y tus acompañantes. Contaba con la ventaja de tener tracción en las cuatro ruedas.",
    image: "/assets/images/home/legado/cars/Jeep_1962_Grand_Wagoneer.png",
  },
  {
    id: 7,
    model: "Grand Wagoneer SJ",
    year: "1963",
    color: "#474c41",
    desc: "<strong>#Jeep Grand Wagoneer SJ</strong> capturó cada mirada con su estilo moderno y apariencia moderna. Este modelo fue parte de la línea “Senior” donde también se encontraban Gladiator, J-Series, Wagoneer Station Wagon y los primeros modelos Cherokee.",
    image: "/assets/images/home/legado/cars/Jeep_1963_Wagoneer_SJ.png",
  },
  {
    id: 8,
    model: "Jeepster Comando",
    year: "1967",
    color: "#363640",
    desc: "#Jeep aprovechó para crear un modelo único, enfocado en ser compacto y con tracción Four Wheel Drive. Tenía cuatro versiones distintas: pickup, convertible, vagoneta y roadster.</br>Estaba equipado con un motor Hurricane de 75 HP y 114 Ib-pie de torque, eso lo convirtió en la motorización preferida de los aventureros.",
    image: "/assets/images/home/legado/cars/Jeep_1946_Willys_Wagon.png",
  },
  {
    id: 9,
    model: "Grand Cherokee XJ",
    year: "1984",
    color: "#50443b",
    desc: 'La segunda generación de <strong>Grand Cherokee</strong> incorpora una robusta carrocería y podías encontrarla con tres y cinco puertas. En 1984, fue catalogado como el "4X4 del año".</br><strong>XJ</strong> fue el primer vehículo con dos sistemas 4x4 disponibles: Command-Trac ® Part-Time y Selec-Trac ® Full-Time 4x4, convirtiéndolo en un SUV todoterreno.',
    image: "/assets/images/home/legado/cars/Jeep_1984_Grand_Cherokee_XJ.png",
  },
  {
    id: 10,
    model: "Grand Cherokee ZJ",
    year: "1993",
    color: "#4c4c33",
    desc: "<strong>#Jeep Grand Cherokee (ZJ)</strong> fue el primer SUV equipado, estableció nuevos estándares para conducir cómodamente en viajes de carretera. Tuvo popularidad inmediata y ganó docenas de premios, incluido “Truck of the Year” de la revista Motor Trend, “Four Wheeler of the Year“ de la revista 4 Wheel & Off-Road y el “4X4 del año” en 2003.",
    image: "/assets/images/home/legado/cars/Jeep_1993_Grand_Cherokee_ZJ.png",
  },
  {
    id: 11,
    model: "Wrangler YJ",
    year: "1995",
    color: "#665d42",
    desc: "<strong>#Jeep Wrangler (YJ)</strong> tenía una parrilla en ángulo, faros rectangulares y un interior moderno para su año. Todo esto lo convirtió en un Jeep muy “civilizado”. Solo se ocuparon faros cuadrados para este modelo. Tuvo distintos niveles de equipamiento como: Base, Laredo, Islander, Sport, Sahara, Renegade y Río Grande.",
    image: "/assets/images/home/legado/cars/Jeep_1995_Wrangler_YJ.png",
  },
  {
    id: 12,
    model: "Jeep Liberty KJ",
    year: "2002",
    color: "#474c41",
    desc: "<strong>#Jeep Liberty</strong> tenía un diseño que para su año era innovador, como faros redondos y una rejilla de siete ranuras. También fue el primer Jeep en usar los dos motores ”PowerTech”.</br><strong>Liberty</strong> fue nominado para el premio “Truck of the Year” de América del Norte en 2002.",
    image: "/assets/images/home/legado/cars/Jeep_2002_Liberty.png",
  },
  {
    id: 13,
    model: "Jeep Wrangler TJ",
    year: "2004",
    color: "#363640",
    desc: '<strong>#Jeep Wrangler Unlimited</strong> es conocido extraoficialmente por nuestros jeeperos como el "LJ".</br>Era 15” más grande, ofrecía más espacio para las piernas en la parte trasera, más espacio interior y un manejo en carretera muy mejorado.',
    image: "/assets/images/home/legado/cars/Jeep_2004_Wrangler_TJ.png",
  },
  {
    id: 14,
    model: "Compass",
    year: "2007",
    color: "#50443b",
    desc: "<strong>#Jeep Compass</strong> fue un vehículo con tracción delantera y una opción de dos sistemas 4x4 controlados electrónicamente, disponibles para su uso en cualquier tipo de camino: Freedom Drive I o Freedom Drive II ® , que lo convirtieron en un todoterreno capaz. El favorito de muchos en el 2007.",
    image: "/assets/images/home/legado/cars/Jeep_2007_Compass.png",
  },
  {
    id: 15,
    model: "Patriot",
    year: "2007",
    color: "#4c4c33",
    desc: "<strong>#Jeep Patriot</strong> fue un SUV que contó con capacidad Trail Rated®. <strong>Patriot</strong> ganó el premio “Green 4x4” en el 2007 y “4x4 of the Year” en 2008. Un modelo extremadamente popular que ofreció numerosas aventuras durante su década.",
    image: "/assets/images/home/legado/cars/Jeep_2007_Patriot.png",
  },
  {
    id: 16,
    model: "Wrangler Unlimited",
    year: "2017",
    color: "#665d42",
    desc: "<strong>​​#Jeep Wrangler Unlimited</strong> es el descendiente moderno del heróico Willys MB.</br>Los faros redondos fueron icónicos del modelo Wrangler 2007.</br>Está diseñado para satisfacer tanto a los fanáticos del todoterreno, como a los conductores de SUV de lujo.",
    image: "/assets/images/home/legado/cars/Jeep_2017_Wrangler_Unlimited.png",
  },
  {
    id: 17,
    model: "Jeep JT",
    year: "2021",
    color: "#474c41",
    desc: "<strong>#Jeep JT</strong> llegó a revolucionar la era de los 4x4. <strong>JT</strong> abrió nuevos caminos en la clase de pickups en 2020 con una legendaria capacidad todoterreno. Con una TrailCam®️ delantera y por primera vez con una certificación Trail Rated®.",
    image: "/assets/images/home/legado/cars/Jeep_2021_Jeep_JT.png",
  },
  {
    id: 18,
    model: "Nuevo Compass",
    year: "2021",
    color: "#363640",
    desc: "<strong>#Jeep Compass</strong> presentó un “diseño 4x4 pequeño y ancho” que combinaba la mejor capacidad de su clase. Contaba con dos sistemas 4x4 inteligentes, los cuales podrían enviar el 100 por ciento del torque disponible a cualquier llanta. <strong>Compass</strong> proporcionó un crossover capaz de usar dentro y fuera de la carretera.",
    image: "/assets/images/home/legado/cars/Jeep_2021_New_Compass.png",
  },
  {
    id: 19,
    model: "Nuevo Wrangler",
    year: "2021",
    color: "#50443b",
    desc: "<strong>#Jeep Wrangler</strong> está listo para sobrepasar cualquier obstáculo sin importar de qué camino se trate. Cuenta con capacidad Trail Rated y tecnología suficiente para salir a cualquier lugar. Su motor Pentastar®️ y sus 285 HP lo convierten en un modelo único en su clase.",
    image: "/assets/images/home/legado/cars/Jeep_2021_New_Wrangler.png",
  },
]

export default historyData
