import React, { useState, useEffect, useContext } from "react"
import { string, shape } from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

import ContextAllImages from "../../../context/allImages.context"

// baseName > 'image.jpg'

const Image = ({ baseName, alt, style, className }) => {
  const [image, setImage] = useState(null)
  const { getTheImage } = useContext(ContextAllImages)

  useEffect(() => {
    setImage(getTheImage(baseName))
  }, [])

  return <>{image && <GatsbyImage image={image} alt={alt} className={className} style={style} />}</>
}

Image.propTypes = {
  baseName: string.isRequired,
  alt: string.isRequired,
  style: shape({}),
  className: string,
}

Image.defaultProps = {
  style: null,
  className: "",
}

export default Image
