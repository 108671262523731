import * as React from 'react';

function SvgPencil({ width = '19', height = '19', className = '' }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 19"
    >
      <path d="M0 15v4h4L15.6 7.3l-4-4L0 15zM18.7 4.2c.4-.4.4-1.1 0-1.5L16.3.3c-.4-.4-1.1-.4-1.5 0l-1.9 1.9 4 4 1.8-2z" />
    </svg>
  );
}

export default SvgPencil;
