import * as React from "react"

function SvgJeepGrill({ width='120.9', height = '33.2', className = '' }) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120.9 33.2"
    >
      <path d="M11.1 0C5 0 0 5 0 11.1s5 11.1 11.1 11.1 11.1-5 11.1-11.1C22.1 5 17.2 0 11.1 0zM29.7 0c-1.4 0-2.6 1.2-2.6 2.1v29c0 .9 1.2 2.1 2.6 2.1s2.6-1.2 2.6-2.1v-29c0-.9-1.1-2.1-2.6-2.1zM40 0c-1.4 0-2.6 1.2-2.6 2.1v29c0 .9 1.2 2.1 2.6 2.1 1.4 0 2.6-1.2 2.6-2.1v-29c0-.9-1.2-2.1-2.6-2.1zM50.2 0c-1.4 0-2.6 1.2-2.6 2.1v29c0 .9 1.2 2.1 2.6 2.1 1.4 0 2.6-1.2 2.6-2.1v-29c0-.9-1.2-2.1-2.6-2.1zM60.4 0c-1.4 0-2.6 1.2-2.6 2.1v29c0 .9 1.2 2.1 2.6 2.1 1.5 0 2.6-1.2 2.6-2.1v-29c0-.9-1.1-2.1-2.6-2.1zM70.7 0c-1.4 0-2.6 1.2-2.6 2.1v29c0 .9 1.2 2.1 2.6 2.1 1.4 0 2.6-1.2 2.6-2.1v-29c0-.9-1.2-2.1-2.6-2.1zM80.9 0c-1.4 0-2.6 1.2-2.6 2.1v29c0 .9 1.2 2.1 2.6 2.1 1.4 0 2.6-1.2 2.6-2.1v-29c0-.9-1.2-2.1-2.6-2.1zM91.1 0c-1.4 0-2.6 1.2-2.6 2.1v29c0 .9 1.2 2.1 2.6 2.1 1.4 0 2.6-1.2 2.6-2.1v-29c0-.9-1.1-2.1-2.6-2.1zM109.8 0c-6.1 0-11.1 5-11.1 11.1s5 11.1 11.1 11.1 11.1-5 11.1-11.1S115.9 0 109.8 0z" />
    </svg>
  )
}

export default SvgJeepGrill