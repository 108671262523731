import styled, { css } from "styled-components"
import { device } from "../../../../theme/mediaQueries"

export const CoverContainer = styled.div`
  pointer-events: none;
  position: relative;
  text-align: center;
  width: 100vw;
`

export const BlockInside = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 790px;
  padding: 150px 0;
  position: relative;
  width: 100%;
  z-index: 1;

  @media ${device.sm} {
    min-height: 1100px;
    padding: 200px 0;
  }
  @media ${device.md} {
    min-height: 1000px;
    padding: 100px 0;
  }
  @media ${device.lg} {
    min-height: 1200px;
  }
  @media ${device.xl} {
    min-height: 1500px;
  }

  ${props =>
    !props.isIOS &&
    css`
      margin-top: -75px;
      @media ${device.sm} {
        margin-top: -20vw;
      }
      @media ${device.xl} {
        margin-top: -25vw;
      }
    `}
`

export const TitleShare = styled.h5`
  font-family: ${props => props.theme.vars.heaters};
  font-weight: ${props => props.theme.vars.fontRegular};
  font-size: 64px;
  line-height: 0.8em;
  color: ${props => props.theme.vars.white};
  letter-spacing: 0.4px;
  margin-bottom: 40px;
  span {
    color: ${props => props.theme.vars.mustard};
    display: block;
  }
  @media ${device.sm} {
    font-size: 110px;
    line-height: 0.7em;
  }
  @media ${device.md} {
    font-size: 115px;
    margin-bottom: 45px;
    span {
      display: inline-block;
    }
  }
  @media ${device.lg} {
    font-size: 140px;
    margin-bottom: 50px;
  }
  @media (min-width: 1700px) {
    font-size: 160px;
    margin-bottom: 40px;
  }
`
export const SubtitleShare = styled.div`
  color: ${props => props.theme.vars.white};
  font-family: ${props => props.theme.vars.avenirNext};
  font-size: 16px;
  font-weight: ${props => props.theme.vars.fontSemiBold};
  line-height: 1em;
  margin-bottom: 25px;
  text-transform: uppercase;

  @media ${device.sm} {
    font-size: 16px;
    margin-bottom: 20px;
  }
  @media ${device.md} {
    font-size: 20px;
    margin-bottom: 15px;
  }
  @media (min-width: 1700px) {
    font-size: 26px;
  }
`
export const DescriptionShare = styled.div`
  font-size: 14px;
  line-height: 1.5em;
  color: ${props => props.theme.vars.white};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  max-width: 400px;
  @media ${device.sm} {
    max-width: 470px;
  }
  @media ${device.md} {
    font-size: 18px;
    max-width: 750px;
    margin-bottom: 50px;
  }
  @media ${device.lg} {
    max-width: 768px;
    margin-bottom: 70px;
  }
  @media (min-width: 1700px) {
    max-width: 975px;
  }
`
