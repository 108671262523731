import styled, { css } from "styled-components"
import { device } from "../../../theme/mediaQueries"

const StyledVideoPlayer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 40px 29px -16px rgb(0 0 0 / 75%);

  video {
    display: block;
    position: relative;
  }

  .c-video-player--play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    .o-svg-roll__typecontainer {
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      transform: none;
    }
  }

  .c-custom-poster {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1.5s ease;
  }

  ${props =>
    props.animatePoster &&
    css`
      .c-custom-poster {
        transform: translate(-50%, -50%) scale(1);
      }
    `}
`

export const StyledButtonPlayer = styled.button`
  color: #1e1e1f;
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: 40px;
  height: 40px;
  line-height: 57px;
  text-align: center;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;

  .c-video-player--pause {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    span {
      display: block;
      width: 3px;
      height: 13px;
      background-color: #000;
    }

    span:nth-child(1) {
      margin-right: 5px;
    }
  }
  @media ${device.sm} {
    width: 53px;
    height: 53px;
    left: 15px;
    bottom: 15px;
  }
`

export default StyledVideoPlayer
