import styled, { css } from "styled-components"
import { device } from "../../../../theme/mediaQueries"
import easeInOutExpo from "../../../../theme/easings"

export const CoverContainer = styled.div`
  /* overflow: hidden; */
  position: relative;
  width: 100vw;
`

export const BlockInside = styled.div`
  /* border: 1px solid red; */
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 790px;
  padding: 150px 0;
  position: relative;
  width: 100%;
  z-index: 1;

  @media ${device.sm} {
    min-height: 1100px;
    padding: 200px 0;
  }
  @media ${device.md} {
    min-height: 1000px;
    padding: 100px 0;
  }
  @media ${device.lg} {
    min-height: 1200px;
  }
  @media ${device.xl} {
    min-height: 1500px;
  }

  ${props =>
    !props.isIOS &&
    css`
      margin-top: -75px;
      @media ${device.sm} {
        margin-top: -20vw;
      }
      @media ${device.xl} {
        margin-top: -25vw;
      }
    `}
`

export const TextContainer = styled.div`
  position: relative;
  text-align: center;
  width: 100%;

  .title-zoom {
    position: relative;
    transform: scale(1.2);
    transition: transform 3s ${easeInOutExpo};

    &.is-zoom-out {
      transform: scale(1);
    }
  }
`

export const TitleContainer = styled.h3`
  color: ${props => props.theme.vars.white};
  font-family: ${props => props.theme.vars.heaters};
  font-size: 73px;
  font-weight: ${props => props.theme.vars.fontRegular};
  line-height: 0.8em;
  position: relative;
  z-index: 3;

  @media ${device.sm} {
    font-size: 104px;
  }
  @media ${device.md} {
    font-size: 110px;
  }
  @media ${device.lg} {
    font-size: 134px;
  }
  @media ${device.xl} {
    font-size: 164px;
  }
`

export const QuoteContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  position: relative;
  z-index: 2;

  .c-cover-legacy__special-badge {
    font-size: 0;
    position: relative;
    margin-right: 4px;
  }
  .c-cover-legacy__special-badge-img {
    fill: ${props => props.theme.vars.anzac};
    height: auto;
    width: 16px;
  }
  .c-cover-legacy__quote {
    color: ${props => props.theme.vars.anzac};
    font-size: 18px;
    font-family: ${props => props.theme.vars.tradeGot};
    font-weight: ${props => props.theme.vars.fontBold};
    letter-spacing: 0px;
    line-height: 1em;
    text-transform: uppercase;
  }

  @media ${device.sm} {
    margin-top: 10px;

    .c-cover-legacy__special-badge-img {
      width: 20px;
    }
    .c-cover-legacy__quote {
      font-size: 22px;
    }
  }
  @media ${device.md} {
    .c-cover-legacy__special-badge-img {
      width: 18px;
    }
    .c-cover-legacy__quote {
      font-size: 20px;
    }
  }
  @media ${device.xl} {
    .c-cover-legacy__special-badge-img {
      width: 22px;
    }
    .c-cover-legacy__quote {
      font-size: 24px;
    }
  }
`

export const DescriptionContainer = styled.div`
  color: ${props => props.theme.vars.white};
  font-family: ${props => props.theme.vars.avenirNext};
  font-size: 16px;
  font-weight: ${props => props.theme.vars.fontRegular};
  line-height: 1.4em;
  letter-spacing: 0px;
  position: relative;
  z-index: 2;

  @media ${device.sm} {
    font-size: 18px;
  }
  @media ${device.md} {
    font-size: 16px;
  }
  @media ${device.lg} {
    font-size: 18px;
  }
  @media ${device.xl} {
    font-size: 20px;
  }
`

export const ImageContainer = styled.div`
  /* border: 1px solid red; */
  display: block;
  height: auto;
  position: relative;
  width: 100%;
  z-index: 1;

  .c-cover-legacy__image {
    left: 50%;
    position: relative;
    transform: translateX(-50%);
    max-width: 850px;
    transition: transform 3s ${easeInOutExpo};
    transform-origin: 50% 50%;
    width: 150vw;
    .gatsby-image-wrapper {
      position: relative;
      transform: scale(0.8);
      transition: transform 3s ${easeInOutExpo};
    }
    &.is-zoom-in {
      .gatsby-image-wrapper {
        transform: scale(1);
      }
    }
  }

  @media ${device.md} {
    .c-cover-legacy__image {
      max-width: 900px;
    }
  }
  @media ${device.lg} {
    .c-cover-legacy__image {
      max-width: 980px;
    }
  }
  @media ${device.xl} {
    .c-cover-legacy__image {
      max-width: 1071px;
    }
  }

  ${props =>
    !props.isIOS &&
    css`
      margin: -2vw 0;
    `}
`
