const footerData = [
  {
    id: 0,
    title: "CONQUISTA CUALQUIER SENDERO",
    bgImage: "/assets/images/footer/jeepstories-footer-01.png",
  },
  {
    id: 1,
    title: "SUPERA TODOS LOS CAMINOS",
    bgImage: "/assets/images/footer/jeepstories-footer-02.png",
  },
  {
    id: 2,
    title: "DESCUBRE NUEVOS TERRENOS",
    bgImage: "/assets/images/footer/jeepstories-footer-06.png",
  },
  {
    id: 3,
    title: "AVENTÚRATE AL MUNDO",
    bgImage: "/assets/images/footer/jeepstories-footer-04.png",
  },
  {
    id: 4,
    title: "CREA TU PROPIA RUTA",
    bgImage: "/assets/images/footer/jeepstories-footer-05.png",
  },
  {
    id: 5,
    title: "VIVE EN LIBERTAD",
    bgImage: "/assets/images/footer/jeepstories-footer-06.png",
  },
  {
    id: 6,
    title: "DESAFÍA LO IMPOSIBLE",
    bgImage: "/assets/images/footer/jeepstories-footer-03.png",
  },
  {
    id: 7,
    title: "ALCANZA LA CIMA",
    bgImage: "/assets/images/footer/jeepstories-footer-06.png",
  },
  {
    id: 8,
    title: "LEGADO TODOTERRENO",
    bgImage: "/assets/images/footer/jeepstories-footer-02.png",
  },
  {
    id: 9,
    title: "CORAZÓN 4X4",
    bgImage: "/assets/images/footer/jeepstories-footer-01.png",
  },
]

export default footerData
