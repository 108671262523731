import React from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { string, number } from "prop-types"

import { isIOS } from "../../../../helpers/platform"
import BgBlock from "../../../elements/bgBlock/BgBlock"
import ButtonText from "../../../elements/buttonText/ButtonText"
import { CoverContainer, BlockInside, TitleShare, SubtitleShare, DescriptionShare } from "./coverShare.styled"

const CoverShare = ({ index, className }) => {
  const classNameID = `c-cover-share`

  return (
    <CoverContainer className={`${classNameID} ${classNameID}_${index} ${className}`}>
      <BgBlock theme="dark" depth={0} className={`${classNameID}__bg-bottom`}>
        <BlockInside isIOS={isIOS()}>
          <Container>
            <Row>
              <Col xs={4} sm={8} md={12}>
                <TitleShare>
                  COMPARTE <span>TUS JEEPSTORIES</span>
                </TitleShare>
                <SubtitleShare>invitación para participar</SubtitleShare>
                <DescriptionShare>Cuéntanos desde tu primera ruta 4x4 hasta esas aventuras familiares y forma parte de nuestros 80 años de historia.</DescriptionShare>
                <ButtonText url="/sube-tu-historia/" className={`${classNameID}__shareButton`} classTag="tag-historias-contar-historia">
                  contar mi historia
                </ButtonText>
              </Col>
            </Row>
          </Container>
        </BlockInside>
      </BgBlock>
    </CoverContainer>
  )
}

CoverShare.propTypes = {
  index: number,
  className: string,
}

CoverShare.defaultProps = {
  index: 0,
  className: "",
}

export default React.memo(CoverShare)
