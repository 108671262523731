import React from "react"
import PropTypes from "prop-types"

import { ButtonAddHistoryStyle, ButtonAddHistoryContainer } from "./ButtonAddHistory.style"
import SvgPencil from "../svgs/SvgPencil"

const ButtonAddHistory = ({ url, className }) => {
  const classNameID = "o-button-add-history"

  return (
    <ButtonAddHistoryStyle to={url} className="tag-historias-compartir-historia">
      <ButtonAddHistoryContainer className={`${classNameID} ${className}`}>
        <div className={`${classNameID}__bgnormal ${classNameID}__bg`} />
        <div className={`${classNameID}__bgroll ${classNameID}__bg`} />
        <SvgPencil className={`${classNameID}__svgnormal ${classNameID}__svg`} />
        <SvgPencil className={`${classNameID}__svgroll ${classNameID}__svg`} />
      </ButtonAddHistoryContainer>
    </ButtonAddHistoryStyle>
  )
}

ButtonAddHistory.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
}

ButtonAddHistory.defaultProps = {
  url: "/sube-tu-historia/",
  className: "",
}

export default ButtonAddHistory
