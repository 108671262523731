import React, { useRef, useEffect } from "react"
import gsap from "gsap"

import useNearScreen from "../../../../hooks/useNearScreen"
import useWindowSize from "../../../../hooks/useWindowSize"
import { isIOS } from "../../../../helpers/platform"
import VideoPlayer from "../../../elements/videoPlayer/VideoPlayer"
import StyledHomeVideo from "./HomeVideo.style"

const HomeVideo = ({ className = "" }) => {
  const classNameID = `o-home-video`
  const { element, show } = useNearScreen({ threshold: 0.1, onlyShow: true })
  const tl = useRef(null)
  const base = useRef(null)
  const windowSize = useWindowSize()

  useEffect(() => {
    // console.log('windowSize', windowSize);
    if (windowSize.width === 0) return null

    if (!isIOS()) {
      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: base.current,
          start: "top center",
          end: `${windowSize.width < 1024 ? "200%" : "120%"} bottom`,
          scrub: 0.5,
          toggleClass: "is-active",
          // markers: true,
        },
      })

      gsap.utils.toArray(`.o-home-video .parallax`).forEach(layer => {
        const { depth } = layer.dataset
        const movement = -(layer.offsetHeight * depth)
        tl.current.to(layer, { y: movement, ease: "none" }, 0)
      })
    }

    return () => {
      if (tl.current) {
        tl.current.kill()
        tl.current = null
      }
    }
  }, [windowSize.width])

  return (
    <StyledHomeVideo className={`${classNameID} ${className}`}>
      <div className={`${classNameID}__trigger`} ref={base} />
      <div className={`${classNameID}__stage parallax`} data-depth={0.3}>
        <div className={`${classNameID}__video`}>
          <div className={`${classNameID}__container ${show ? "is-zoom-in" : ""}`} ref={element}>
            <VideoPlayer url="https://www.youtube.com/watch?v=EMfb9gs3Eik" />
          </div>
        </div>
      </div>
    </StyledHomeVideo>
  )
}

export default HomeVideo
