import React from 'react';

import StickyAddStoryContainer from './StickyAddStory.style';
import ButtonAddHistory from '../../elements/buttonAddHistory/ButtonAddHistory';

const StickyAddStory = ({ className = '' }) => {
  const classNameID = 'o-sticky-add-story';
  return (
    <StickyAddStoryContainer className={`${classNameID} ${className}`}>
      <div className={`${classNameID}__container`}>
        <div className={`${classNameID}__text-container`}>
          <span className={`${classNameID}__text`}>Comparte tu historia</span>
        </div>
        <div className={`${classNameID}__btn-container`}>
          <ButtonAddHistory />
        </div>
      </div>
    </StickyAddStoryContainer>
  );
};

export default StickyAddStory;
