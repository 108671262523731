import styled from "styled-components"
import { Link } from "gatsby"

import { device } from "../../../theme/mediaQueries"

export const ButtonAddHistoryStyle = styled(Link)`
  &:hover {
    @media ${device.md} {
      .o-button-add-history__bgroll {
        opacity: 1;
      }
      .o-button-add-history__svgnormal {
        transform: translateY(250%);
      }
      .o-button-add-history__svgroll {
        transform: translateY(0);
      }
    }
  }
`

export const ButtonAddHistoryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  box-shadow: 2px 2px 6px 0px rgb(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  pointer-events: none;
  position: relative;

  svg {
    height: 12px;
    width: 12px;
    fill: ${props => props.theme.vars.white};
  }
  .o-button-add-history__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .o-button-add-history__bgroll {
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.vars.goldenTainoi};
    opacity: 0;
    transition: all 0.5s ease;
    z-index: 2;
  }
  .o-button-add-history__bgnormal {
    background-color: ${props => props.theme.vars.charade};
    z-index: 1;
  }

  .o-button-add-history__svg {
    position: absolute;
    transition: all 0.5s ease;
    z-index: 2;
  }
  .o-button-add-history__svgroll {
    color: ${props => props.theme.vars.outerSpace};
    transform: translateY(-250%);
  }

  @media ${device.lg} {
    height: 60px;
    width: 60px;
    svg {
      height: 16px;
      width: 16px;
    }
  }
  @media ${device.xl} {
    height: 80px;
    width: 80px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
`
