import styled from "styled-components"
import { device } from "../../../../theme/mediaQueries"
import easeInOutExpo from "../../../../theme/easings"

const StyledHomeCover = styled.div`
  background-color: ${props => props.theme.vars.steelGray};
  display: block;
  height: 400px;
  pointer-events: none;
  position: relative;
  width: 100%;
  z-index: 10;

  .o-home-cover__bg {
    height: auto;
    left: 50%;
    position: absolute;
    pointer-events: none;
    top: 0;
    transform: translateX(-50%) scale(1.2);
    transition: transform 3s ${easeInOutExpo};
    width: 550px;

    &.is-zoom-out {
      transform: translateX(-50%) scale(1);
    }

    .o-home-cover__gradient {
      width: 100%;
      height: 50px;
      z-index: 10;
      position: absolute;
      background: linear-gradient(to bottom, #4f555c, transparent);
      @media ${device.sm} {
        height: 80px;
      }
      @media ${device.md} {
        height: 100px;
      }
      @media ${device.lg} {
        height: 130px;
      }
    }
  }

  .o-home-cover__title {
    left: 50%;
    opacity: 0;
    position: absolute;
    max-width: 480px;
    top: 65px;
    transform: translateX(-50%);
    width: 90%;

    &.is-visible {
      opacity: 1;
    }
  }

  @media (min-width: 360px) {
    height: 450px;

    .o-home-cover__bg {
      width: 650px;
    }
  }
  @media (min-width: 650px) {
    height: 600px;

    .o-home-cover__bg {
      width: 900px;
    }
  }
  @media ${device.sm} {
    height: 580px;

    .o-home-cover__bg {
      width: 1024px;
    }
    .o-home-cover__title {
      max-width: 580px;
      top: 75px;
      width: 100%;
    }
  }
  @media ${device.md} {
    height: 820px;

    .o-home-cover__bg {
      width: 1366px;
    }
    .o-home-cover__title {
      max-width: 710px;
      top: 145px;
    }
  }
  @media ${device.lg} {
    height: 900px;

    .o-home-cover__bg {
      width: 1500px;
    }
    .o-home-cover__title {
      max-width: 760px;
      top: 165px;
    }
  }
  @media (min-width: 1600px) {
    height: 1150px;

    .o-home-cover__bg {
      width: 1920px;
    }
    .o-home-cover__title {
      max-width: 950px;
      top: 210px;
    }
  }
  @media ${device.xl} {
    height: 1252px;

    .o-home-cover__bg {
      width: 100vw;
    }
  }
`

export default StyledHomeCover
