import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const HomeContainer = styled.div`
  position: relative;
`

export const TitleShare = styled.h5`
  font-family: ${props => props.theme.vars.avenirNext};
  font-weight: ${props => props.theme.vars.fontBold};
  font-size: 30px;
  line-height: 30px;
  color: ${props => props.theme.vars.white};
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 10px;
  span {
    color: ${props => props.theme.vars.mustard};
  }
  @media ${device.sm} {
    font-size: 38px;
    margin-bottom: 40px;
  }
  @media ${device.md} {
    font-size: 48px;
    margin-bottom: 32px;
  }
  @media ${device.lg} {
    font-size: 56px;
    line-height: 48px;
  }
  @media ${device.xl} {
    font-size: 72px;
    line-height: 58px;
    margin-bottom: 40px;
  }
`
export const SubtitleShare = styled.div`
  font-family: ${props => props.theme.vars.avenirNext};
  font-size: 14px;
  color: ${props => props.theme.vars.white};
  text-transform: uppercase;
  margin-bottom: 32px;
  @media ${device.sm} {
    font-size: 16px;
    margin-bottom: 10px;
  }
  @media ${device.lg} {
    font-size: 18px;
  }
  @media ${device.xl} {
    font-size: 24px;
  }
`
export const DescriptionShare = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.vars.white};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 56px;
  max-width: 235px;
  @media ${device.sm} {
    line-height: 18px;
    max-width: 100%;
    margin-bottom: 48px;
  }
  @media ${device.md} {
    line-height: 22px;
    max-width: 750px;
    margin-bottom: 65px;
  }
  @media ${device.lg} {
    font-size: 16px;
    line-height: 24px;
    max-width: 720px;
    margin-bottom: 54px;
  }
  @media ${device.xl} {
    font-size: 18px;
    line-height: 24px;
    max-width: 810px;
    margin-bottom: 66px;
  }
`
